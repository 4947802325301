import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import { Card, CardColumns } from "reactstrap"

import oval1 from "../images/oval.svg"
import oval2 from "../images/oval-2.svg"
import oval3 from "../images/oval-3.svg"
import quote from "../images/quote.svg"
import headlineOverlay from "../images/headline-overlay.svg"

const Testimonials = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO
        title="LeadRoll Testimonials"
        description="LeadRoll has helped hundreds of entrepreneurs generate leads and scale their B2B sales efforts. Check out our archive of testimonials and client reviews!"
      />
      <div
        className="container-fluid headline-section"
        style={{ background: "#fff" }}
      >
        <div className="row">
          <div className="col-lg-6 headline-content p-relative vertical-align d-none-mobile">
            <div className="brandstamp-desktop">
              <h1 className="text-leadblue mb-3">
                <strong>See What Our Customers Say About LeadRoll</strong>
              </h1>
              <h4 className="mb-4 text-secondary">
                Or, write a <Link to="/">new testimonial →</Link>
              </h4>
            </div>
            <img className="headline-oval" src={oval1} alt="Oval Svg" />
          </div>
          <div className="col-lg-6 p-5 testimonialheadline-img vertical-align">
            <img
              className="headline-overlay"
              src={headlineOverlay}
              alt="Headline Overlay"
            />
            <div className="brandstamp d-none-desktop">
              <h1 className="text-leadblue mb-3 text-white">
                <strong>See What Our Customers Say About LeadRoll</strong>
              </h1>
              <h4 className="mb-4 text-secondary">
                Or, write a <Link to="/">new testimonial →</Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ background: "#fff" }}
        className="container-fluid testimonial-body p-relative"
      >
        <div className="container">
          <h2 className="testimonial-title text-center text-leadblue">
            Testimonials
          </h2>
          <div className="row">
            <div className="col-md-12">
              <CardColumns>
                {data.allSanityTestimonial.edges.map(testimonial => (
                  <Card>
                    <div className="testimonial-container">
                      <div className="testimonial-head">
                        <img
                          className="testimonial-quote"
                          src={quote}
                          alt="Quote"
                        />
                        <div
                          className="blog-item  mb-4 h-100"
                          key={testimonial}
                        >
                          <div className="blog-info text-left">
                            <h4 className="text-dark text-center">
                              <strong>{testimonial.node.name}</strong>
                            </h4>
                            <h5 className="text-light-blue text-center font-weight-normal">
                              {testimonial.node.position}
                            </h5>
                            <p className="mt-3">{testimonial.node.body}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
              </CardColumns>
            </div>
            <img className="oval-svg" src={oval1} alt="Oval svg" />
            <img className="oval-2" src={oval2} alt="Oval svg" />
            <img className="oval-3" src={oval3} alt="Oval svg" />
          </div>
          {/*          <nav>
            <ul className="pagination mt-5">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">«</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  4
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  5
                </a>
              </li>
              <li className="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">»</span>
                </a>
              </li>
            </ul>
          </nav>*/}
        </div>
      </div>
      {/* <div className="testimonial-second-section text-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="section-title">Give LeadRoll a Try</h2>
              <h4 className="section-subtitle d-block mb-5">
                And See What All The Fuss Is About...
              </h4>
              <Link to="/" className="testimonial-btn">
                KNOW MORE
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}

export default Testimonials

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityTestimonial {
      edges {
        node {
          id
          body
          name
          position
        }
      }
    }
  }
`
